//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    // pages() {
    //   // return this.$store.state.pages;
    //   let pagez = this.$t("pages.navItems");
    //   console.log(pagez);
    //   return pagez;
    // },
    // features() {
    //   return this.$store.state.features;
    // },
    // companyName() {
    //   return this.$store.state.companyName;
    // },
    // locale() {
    //   return this.$store.state.locale;
    // },
    availableLocales() {
      return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale )
    }
  },
  data () {
    return {
      drawer: false,
    }
  },
  // async mounted() {
  //   this.$store.dispatch('getPages');
  //   this.$store.dispatch('getFeatures');
  //   this.$store.dispatch('getCompanyInfo');
  //   this.$store.dispatch('getStrains');
  // }
}
